import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { getMarkerColor } from '../../../../utils'

const SMap = styled.div`
    height: 100vh;
`

export const GoogleMap = ({ markers }) => {
    const [map, setMap] = useState(null)

    const initMarkers = async () => {
        const { AdvancedMarkerElement, PinElement } =
            await window.google.maps.importLibrary('marker')
        const { InfoWindow } = await window.google.maps.importLibrary('maps')

        const infoWindow = new InfoWindow()

        markers.forEach(({ lng, lat, name, type, image, address }) => {
            const color = getMarkerColor(type)

            const pinBackground = new PinElement({
                background: color,
                borderColor: color,
                glyphColor: 'white',
                scale: 1.2,
            })

            const marker = new AdvancedMarkerElement({
                position: { lat, lng },
                title: name,
                map,
                content: pinBackground.element,
            })

            marker.addListener('click', () => {
                map.setCenter({ lng, lat });
                
                const src = image ? `images/${image}` : 'placeholder.jpg'

                infoWindow.close()
                infoWindow.setContent(`
                    <div class="map-popup">
                        <img class="map-popup__image" src="${src}" alt="${name} logo">
                        <div>
                            <p class="map-popup__name">${name}</p>
                            <p>(${type})</p>
                            <p>${address}</p>
                        </div>
                    </div>
                `)
                infoWindow.open(marker.map, marker)
            })
        })
    }

    const initMap = async () => {
        const { Map } = await window.google.maps.importLibrary('maps')

        const googleMap = new Map(
            document.getElementById('google-map-container'),
            {
                center: { lat: 51.9194, lng: 19.1451 },
                zoom: 6.5,
                mapId: process.env.REACT_APP_MAP_ID,
            }
        )

        setMap(googleMap)
    }

    useEffect(() => {
        initMap()
    }, [])

    useEffect(() => {
        if (map && markers.length > 0) initMarkers()
    }, [map, markers])

    return <SMap id="google-map-container"></SMap>
}
