import React from 'react'
import { styled } from 'styled-components'

const SMenu = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    gap: 8px;
    cursor: pointer;
    border-radius: 8px;
    background-color: ${({ theme: { colors } }) => colors.dark_green};
    transition: all 0.3s linear;

    &:hover {
        background-color: ${({ theme: { colors } }) => colors.red};
    }

    &:active,
    &:focus {
        background-color: ${({ theme: { colors } }) => colors.dark_red};
    }
`

const SBar = styled.span`
    width: 40px;
    height: 4px;
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 2px;
    transition: all 0.3s linear;
`

const STopBar = styled(SBar)`
    ${({ opened }) =>
        opened &&
        `
    transform: translateY(12px) rotate(45deg)
`};
`

const SMiddleBar = styled(SBar)`
    opacity: ${({ opened }) => (opened ? 0 : 1)};
`

const SBottomBar = styled(SBar)`
    ${({ opened }) =>
        opened &&
        `
    transform: translateY(-12px) rotate(-45deg)
`};
`

export const MenuIcon = ({ opened, onToggle }) => {
    return (
        <SMenu onClick={() => onToggle(!opened)}>
            <STopBar opened={opened} />
            <SMiddleBar opened={opened} />
            <SBottomBar opened={opened} />
        </SMenu>
    )
}
