import { TYPES } from '../constants/types'
import { THEME } from '../theme/theme'

export const getMarkerColor = (type) => {
    const normalizedType = type.toLowerCase().trim()

    const { red, green, brick, yellow, blue, forest_green, black, lavender, pink, graphite } = THEME.colors;


    const COLOR_MAP = {
        [TYPES.RESTAURANT]: red,
        [TYPES.POINT]: green,
        [TYPES.SHOP]: pink,
        [TYPES.HEADQUATERS]: brick,
        [TYPES.DIRECT_SALE]: yellow,
        [TYPES.BUSHCRAFT]: blue,
        [TYPES.FOREST_COMPLEX]: forest_green,
        [TYPES.SHOOTING_RANGE]: black,
        [TYPES.GUNSMITH]: graphite,
        [TYPES.INTERESTING_PLACE]: lavender
    }

    return COLOR_MAP[normalizedType] || lavender;
}
