export const TYPES = {
    RESTAURANT: 'restauracja',
    SHOP: 'sklep',
    POINT: 'punkt skupu',
    HEADQUATERS: 'siedziba pzł',
    DIRECT_SALE: 'sprzedaż bezpośrednia',
    BUSHCRAFT: 'miejsce do bushcraftu',
    FOREST_COMPLEX: 'leśne kompleksy promocyjne',
    INTERESTING_PLACE: 'ciekawe miejsce',
    SHOOTING_RANGE: 'strzelnica',
    GUNSMITH: 'rusznikarnia'
};