import React from 'react'
import { Marker } from '../../../../components/Marker'
import { TYPES } from '../../../../constants/types'
import { styled } from 'styled-components'

const SLegendWrapper = styled.div`
    border-top: 1px solid ${({ theme: { colors } }) => colors.grey};
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    padding-top: 40px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.mobile}) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
`

const SImage = styled.img`
    width: 400px;
    height: 400px;
    object-fit: contain;
`

const SListItem = styled.li`
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
    align-items: center;
    text-transform: uppercase;
`

const SHeader = styled.h2`
    margin-bottom: 20px;
`

export const Legend = () => {
    return (
        <SLegendWrapper id="legend">
            <SImage src="assets/logo.png" alt="Logo" />

            <div>
                <SHeader>Legenda:</SHeader>
                <ul>
                    {Object.values(TYPES).map(type => <SListItem>
                        <Marker type={type} /> - {type}
                    </SListItem>)}
                </ul>
            </div>
        </SLegendWrapper>
    )
}
