import './App.css'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AppRoutes } from './router/Routes'
import { Navbar } from './navigation/Navbar'
import { Footer } from './footer/Footer'
import { ThemeProvider, styled } from 'styled-components'
import { THEME } from './theme/theme'

const SAppWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.white};
    color: ${({ theme: { colors } }) => colors.dark_green};
    min-height: 100vh;
`

const App = () => {
    return (
        <ThemeProvider theme={THEME}>
            <Router>
                <SAppWrapper>
                    <Navbar />

                    <AppRoutes />
                </SAppWrapper>
                <Footer />
            </Router>
        </ThemeProvider>
    )
}

export default App
