import React from 'react'
import { styled } from 'styled-components'
import { ReactComponent as MailIcon } from './icons/mail.svg'
import { ReactComponent as PhoneIcon } from './icons/phone.svg'

const SFooter = styled.div`
    max-width: 100vw;
    overflow: hidden;
    background-color: ${({ theme: { colors } }) => colors.dark_green};
    color: ${({ theme: { colors } }) => colors.beige};
    padding: 80px 0 0;
    text-align: center;
    border-top: 1px solid ${({ theme: { colors } }) => colors.beige};
`

const SFooterContent = styled.div`
    padding: 0 20px;

    p {
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
    }
`

const SCopyright = styled.div`
    border-top: 1px solid ${({ theme: { colors } }) => colors.beige};
    padding: 40px 20px;
    background-color: ${({ theme: { colors } }) => colors.dark_red};
    margin-top: 40px;
`

const SBold = styled.span`
    font-weight: bold;
`

export const Footer = () => {
    const year = new Date().getFullYear()

    return (
        <SFooter id="contact">
            <SFooterContent>
                <p>
                    <SBold>Chcesz aby Twój punkt znalazł się na mapie?</SBold>
                </p>
                <p>Skontaktuj się z nami:</p>
                <p>
                    <PhoneIcon width={30} />
                    <SBold>+48 661 330 575</SBold>
                </p>
                <p>
                    <MailIcon width={30} />
                    <SBold>hubertdziku@gmail.com</SBold>
                </p>
            </SFooterContent>

            <SCopyright>
                <p>
                    <SBold>Copyright © Dziki Szlak {year}.</SBold> Wszelkie
                    prawa zastrzeżone.
                </p>
            </SCopyright>
        </SFooter>
    )
}
