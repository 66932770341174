import React, { useState } from 'react'
import { styled } from 'styled-components'
import { MenuIcon } from './_components/menu-icon/MenuIcon'
import { Menu } from './_components/menu/Menu'

const SNavbar = styled.nav`
    height: 80px;
    color: ${({ theme: { colors } }) => colors.white};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    width: 100vw;
`

const SInnerWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => colors.dark_green};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    z-index: 10000000;
`

const SLogoWrapper = styled.a`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${({ theme: { colors } }) => colors.white};
    border: 2px solid ${({ theme: { colors } }) => colors.green};
    object-fit: contain;
    padding: 3px;
`

export const Navbar = () => {
    const [menu, setMenu] = useState(false)

    return (
        <SNavbar>
            <Menu opened={menu} />
            <SInnerWrapper>
                <SLogoWrapper href="/">
                    <img src="assets/logo.png" alt="Logo" />
                </SLogoWrapper>
                <MenuIcon opened={menu} onToggle={setMenu} />
            </SInnerWrapper>
        </SNavbar>
    )
}
