import React from 'react'
import { styled } from 'styled-components'
import { NAV_LINKS } from '../../navigation.constants'
import { Link, animateScroll as scroll } from 'react-scroll'

const SMenu = styled.div`
    position: fixed;
    top: ${({ opened }) => (opened ? '80px' : '-200px')};
    transition: all 0.3s linear;
    left: 0;
    width: 100vw;
    height: 200px;
    background-color: ${({ theme: { colors } }) => colors.dark_red};
    z-index: -1;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.mobile}) {
        height: 120px;
        top: ${({ opened }) => (opened ? '80px' : '-40px')};
    }
`

const SItems = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding: 20px 40px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.mobile}) {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
`

const SItem = styled.li`
    padding: 0 20px;
    cursor: pointer;

    .active {
        color: ${({ theme: { colors } }) => colors.beige};
    }
`

export const Menu = ({ opened }) => {
    return (
        <SMenu opened={opened}>
            <SItems>
                {NAV_LINKS.map(({ label, id }) => (
                    <SItem key={`${label}-${id}`}>
                        <Link
                            to={id}
                            spy={true}
                            activeClass="active"
                            smooth={true}
                            offset={-500}
                            duration={500}
                        >
                            {label}
                        </Link>
                    </SItem>
                ))}
            </SItems>
        </SMenu>
    )
}
