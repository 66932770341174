export const THEME = {
    colors: {
        red: '#650101',
        dark_red: '#190000',
        green: '#335B39',
        dark_green: '#070c07',
        beige: '#F1BF98',
        grey: '#4F4F4F',
        white: '#ffffff',
        yellow: '#F0C808',
        graphite: '#292F36',
        blue: '#7192BE',
        black: '#020100',
        forest_green: '#648767',
        brick: '#8E443D',
        lavender: '#654597',
        pink: '#FCB0B3'
    },
    breakpoints: {
        mobile: '768px',
    },
}
