import React from 'react'
import { Intro } from './_sections/intro/Intro'
import { Map } from './_sections/map/Map'
import { Legend } from './_sections/legend/Legend'

export const Home = () => {
    return (
        <>
            <Intro />
            <Map />
            <Legend />
        </>
    )
}
