import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { GoogleMap } from './GoogleMap'
import axios from 'axios'
import { Loader } from '../../../../components/Loader'

const SMapContainer = styled.div`
    min-height: 100vh;
    position: relative;
`

const SError = styled.p`
    color: ${({ theme: { colors } }) => colors.red};
    margin: 20px 0;
    text-align: center;
`

const SLoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    background-color: rgba(255, 255, 255, 0.6);
`

export const Map = () => {
    const [markers, setMarkers] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const loadMarkers = async () => {
        setLoading(true)

        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_API_URL}/markers`
            )

            setMarkers(data)
        } catch {
            setError(true)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 800)
        }
    }

    useEffect(() => {
        loadMarkers()
    }, [])

    return (
        <SMapContainer id="map">
            <GoogleMap markers={markers} />

            {loading && (
                <SLoaderWrapper>
                    <Loader />
                </SLoaderWrapper>
            )}

            {error && (
                <SError>
                    Something went wrong while fetching data. Refresh the page
                    to try again.
                </SError>
            )}
        </SMapContainer>
    )
}
