export const NAV_LINKS = [
    {
        label: 'Mapa',
        id: 'map',
    },
    {
        label: 'Legenda',
        id: 'legend',
    },
    {
        label: 'Kontakt',
        id: 'contact',
    },
]
