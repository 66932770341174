import React from 'react'
import { styled } from 'styled-components'

const SIntro = styled.div`
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-image: url('assets/background-main.jpg');
    background-color: ${({ theme: { colors } }) => colors.dark_green};
    background-size: cover;
    position: relative;
    background-position: center;
`

const SContent = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    color: ${({ theme: { colors } }) => colors.white};
    text-align: center;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.mobile}) {
        align-items: flex-end;
        padding: 80px;
        text-align: right;
    }
`

const SHeader = styled.h1`
    text-transform: uppercase;
    margin-bottom: 20px;

    @media screen and (min-width: ${({ theme: { breakpoints } }) =>
            breakpoints.mobile}) {
        font-size: 48px;
    }
`

const SText = styled.p`
    max-width: 600px;
`

export const Intro = () => {
    return (
        <SIntro>
            <SContent>
                <SHeader>Prosto z lasu.</SHeader>
                <SText>
                    Pierwsza w Polsce interaktywna mapa z punktami z dziczyzną.
                    <br />
                    Znajdź naturę blisko siebie.
                </SText>
            </SContent>
        </SIntro>
    )
}
