import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ROUTES } from './routes.constants'

export const AppRoutes = () => {
    return (
        <Routes>
            {ROUTES.map((props, i) => (
                <Route key={i} {...props} />
            ))}
        </Routes>
    )
}
